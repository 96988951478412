import axios from 'axios';
import store from "@/store";
import router from "@/router";
import ApiService from "@/services/api.service";

// Loads initial data needed for checking capabilities
//  - Producing Offices
//  - Policy Class Types
//  - Countries
const DefaultsService = {
  async loadDefaults() {
    let success = true;
    if (process.env.VUE_APP_ICEDE_CLIENT !== process.env.VUE_APP_ICEDE_CLIENT_TMG) {
      // Load POs
      // Select PO if not already selected or if current selection is invalid
      success = await this.loadPOs();
    }

    if (!success) {
      console.log("There was an error loading producing offices, API will fallback to default PO");
    }
  },
  async loadPOs() {
    try {
      let { data } = await ApiService.get('company/producingOffices');

      const offices = data.producing_offices;
      let officeToSelect = 0;
      if (!offices || offices.length === 0) {
        // No offices available, clear POs and selected PO ID
        store.commit("setProducingOffices", []);
        return;
      } else if (store.getters.producing_office_id) {
        const selectedOffice = store.getters.producingOffices.find(office => office.company_id == store.getters.producing_office_id);
        if (!selectedOffice) {
          // Currently selected PO ID is no longer available, switch to the first available
          officeToSelect = offices[0].company_id;
        } else {
          officeToSelect = selectedOffice.company_id;
        }
      } else {
        // No PO selected, select the first available
        officeToSelect = offices[0].company_id;
      }

      store.commit("setProducingOffices", { offices });

      this.selectProducingOffice(officeToSelect);

      this.setupRequestDefaults();

    } catch (err) {
      console.error("occurred while fetching producing offices:", err);
      return false;
    }

    return true;
  },
  setupRequestDefaults() {
    if (store.getters.producing_office_id === null) {
      delete axios.defaults.headers.common['PO-ID'];
      return;
    }
    axios.defaults.headers.common['PO-ID'] = store.getters.producing_office_id;
  },
  reloadDefaultData() {
    // Clear and load Policy Class Types
    store.dispatch("loadClassTypes");
    
    // Clear and load countries
    store.commit('clearCountries');
    store.dispatch('loadCountriesForContinent');

    // Load currencies and classes of business with the PO ID now selected
    store.dispatch("loadCurrencies");
    if (store.getters.classType !== null) {
      store.dispatch("changeClassOfBusiness", {
        classType: store.getters.classType
      });
    }
  },
  selectProducingOffice(id, fromDomain) {
    console.log("Selecting Producing Office", id, fromDomain);
    store.commit("setPOID", { id, fromDomain });
    this.setupRequestDefaults();

    this.reloadDefaultData();

    // TODO: Create a router service that checks which route we are on before navigating
    if (router.currentRoute.name !== "Country Select" 
      && router.currentRoute.name !== "Login"
      && router.currentRoute.path !== "/"
    ) {
      router.push({name: "Country Select"});
    }
  },
}

export default DefaultsService;
