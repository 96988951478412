import axios from "axios";
import * as Sentry from "@sentry/vue"

export const user = {
  state: () => ({
    userObject: null,
    producingOffices: [],
    userChanges: false,
    verifyingToken: false,
    tokenExpired: false,
    emailSent: false,
    loggingOut: false,
    lastUrlName: "Country Select",
    loggedIn: false,
    timeAuthLastChecked: null,
    producing_office_id: null,
    icede_sub_client: null,
    po_id_set_from_domain: false,
  }),
  mutations: {
    setUser(state, payload) {
      state.userObject = payload.user;
      state.loggedIn = state.userObject !== null;
      state.verifyingToken = false;
      state.userChanges = false;
    },
    setLoggedIn(state, { loggedIn }) {
      state.loggedIn = loggedIn;
    },
    setVerifyingToken(state, payload) {
      state.verifyingToken = payload.verifying;
    },
    setTokenExpired(state, payload) {
      state.tokenExpired = payload.expired;
    },
    setEmailSent(state, payload) {
      state.emailSent = payload.sent;
    },
    setLoggingOut(state, payload) {
      state.loggingOut = payload.loggingOut;
    },
    setLastUrl(state, payload) {
      state.lastUrlName = payload.name;
    },
    updateDetails(state, payload) {
      if (payload.forename) {
        state.userObject.forename = payload.forename;
        state.userChanges = true;
      }
      if (payload.surname) {
        state.userObject.surname = payload.surname;
        state.userChanges = true;
      }
      if (payload.office) {
        state.userObject.office = payload.office;
        state.userChanges = true;
      }
    },
    setLastAuthTime(state, payload) {
      state.timeAuthLastChecked = payload.time;
    },
    setProducingOffices(state, { offices }) {
      state.producingOffices = offices;
    },
    setPOID(state, { id, fromDomain }) {
      state.po_id_set_from_domain = false;
      state.icede_sub_client = null;
      if (isNaN(parseInt(id))) {
        return;
      }
      state.producing_office_id = parseInt(id);
      // Handle the case where the PO ID is set from the domain used to access this instance
      // - set the sub-client which is used to get the correct branding images in the views
      if (fromDomain) {
        state.po_id_set_from_domain = true;
        if (id == process.env.VUE_APP_DOMAIN_MAPPED_URL_A_POID) {
          state.icede_sub_client = process.env.VUE_APP_DOMAIN_MAPPED_URL_A_SUB_CLIENT;
        } else if (id == process.env.VUE_APP_DOMAIN_MAPPED_URL_B_POID) {
          state.icede_sub_client = process.env.VUE_APP_DOMAIN_MAPPED_URL_B_SUB_CLIENT;
        }
      }
    },
  },
  actions: {
    async setUserObject({ commit }) {
      const url = process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/user";

      try {
        let { data } = await axios.get(url, {
          withCredentials: true
        });
        commit("setUser", { user: data });
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    },
    async loginWithToken({ commit, dispatch }, payload) {
      let url = new URL(process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/verifyToken");

      commit("setTokenExpired", { expired: false });
      commit("setVerifyingToken", { verifying: true });
      try {
        let data;

        if (process.env.VUE_APP_USING_COMPLEX_KEY_AUTH === "1" 
          && payload.token === process.env.VUE_APP_AUTH_COMPLEX_KEY
        ) {
          url = new URL(process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/loginWithEmail");
          url.searchParams.set('token', payload.token);
        }

        if (payload.email) {
          url.searchParams.set('email', payload.email);
        }

        if (payload.po) {
          url.searchParams.set('po', payload.po);
        }

        ({ data } = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${payload.token}`
          },
          withCredentials: true
        }));

        commit("setUser", { user: data.user });
        commit("setVerifyingToken", { verifying: false });
        commit("setEmailSent", { sent: false });
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
        commit("setVerifyingToken", { verifying: false });
        commit("setTokenExpired", { expired: true });
        dispatch("resendEmail", { token: payload.token });
        return false;
      }
      return true;
    },
    async resendEmail({ commit }, payload) {
      // TODO: Add a rate limit - create and check time last sent email
      if (!payload.token) {
        console.error("Attemted to resend login email with no token", payload);
        return;
      }
      commit("setEmailSent", { send: true });
      const url = process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/resendEmailForToken";
      const data = await axios.post(url, {
        token: payload.token,
      });
      console.log("resendEmail result", data);
    },
    async logout({ commit, getters }) {
      const bLogoutOnServer = getters.isLoggedIn;
      commit("setUser", { user: null });
      commit("setLoggingOut", { loggingOut: true });
      commit("setEmailSent", { sent: false });

      if (!bLogoutOnServer) {
        commit("setLoggingOut", { loggingOut: false });
        return;
      }

      try {
        await axios.get(process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/logout", {
          withCredentials: true
        });

        commit("setLoggingOut", { loggingOut: false });
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    },
    async checkAuthStatus({ state, commit }) {
      if (!state.loggingOut && !state.verifyingToken) {
        try {
          let { data } = await axios.get(
            process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/user",
            {
              withCredentials: true
            }
          );
          if (data.loggedIn && !state.loggingOut) {
            commit("setUser", { user: data.user });
          } else {
            commit("setUser", { user: null });
          }
          commit("setLastAuthTime", { time: Date.now() });
        } catch (err) {
          console.log("User not logged in");
          // Sentry.captureException(err);
          console.error(err);
          commit("setUser", { user: null });
        }
      } else {
        console.log("User logging out");
      }
    },
  },
  getters: {
    isLoggedIn: state => state.loggedIn,
    user: state => state.userObject,
    verifying: state => state.verifyingToken,
    tokenExpired: state => state.tokenExpired,
    emailSent: state => state.emailSent,
    loggingOut: state => state.loggingOut,
    lastUrlName: state => state.lastUrlName,
    timeAuthLastChecked: state => state.timeAuthLastChecked,
    userIsProducing: state => state.userObject?.flagProducingInquiryUser ?? false,
    producingOffices: state => state.producingOffices,
    selectedProducingOffice: state => state.producingOffices.find(office => office.company_id == state.producing_office_id),
    producing_office_id: state => state.producing_office_id,
    po_id_set_from_domain: state => state.po_id_set_from_domain,
    icede_sub_client: state => state.icede_sub_client,
  }
};
